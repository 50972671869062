import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Typography
  } from '@material-ui/core';
  import { Search as SearchIcon } from 'react-feather';
import { useGiftCard } from 'src/contexts/GiftCardContext';
  
  const GiftCardToolbar = ({ openModal }) => {
    const { search, setSearch, selectedItems, printGiftCards, loading} = useGiftCard();
  
    return (
      <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Typography variant="h2" color="darkText">
        Cartes cadeaux
        </Typography>
        <Box>
        <Button color="primary" variant="contained" onClick={openModal} style={{ marginRight: '8px' }}>
          Ajouter une carte cadeau
        </Button>
        <Button color="secondary" variant="contained" onClick={printGiftCards} disabled={!selectedItems.length || loading}>
          Imprimer
        </Button>
        </Box>
      </div>
      <Box sx={{ mt: 3 }}>
        <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
          <TextField
            fullWidth
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
              </InputAdornment>
            )
            }}
            placeholder="Recherche carte cadeau"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          </Box>
        </CardContent>
        </Card>
      </Box>
      </Box>
    );
  };
  
  export default GiftCardToolbar;
  