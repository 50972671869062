import React from 'react';
import { Card, CircularProgress, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { useGiftCard } from 'src/contexts/GiftCardContext';

const GiftCardResults = ({ onEdit, onDelete, ...rest }) => {
  const { giftCards, page, setPage, pageSize, totalCount, fetching, search, addGiftCard, selectedItems, setSelectedItems } =
    useGiftCard();
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (search.trim()) {
      setList(
        giftCards.filter((g) =>
          g.code.toLowerCase().startsWith(search.toLowerCase())
        )
      );
      return;
    }
    setList(giftCards);
  }, [giftCards, search]);

const columns = [
  {
    field: 'checkbox',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <input
        type="checkbox"
        checked={selectedItems.includes(params.row._id)}
        onChange={() => {
          if (selectedItems.includes(params.row._id)) {
            setSelectedItems(selectedItems.filter((id) => id !== params.row._id));
          } else {
            setSelectedItems([...selectedItems, params.row._id]);
          }
        }}
      />
    )
  },
    {
        field: 'code',
        headerName: 'Code',
        valueFormatter: ({ value }) => value.code,
        flex: 1
    },
    {
        field: 'isUsed',
        headerName: 'Utilisé ?',
        flex: 1,
        valueFormatter: ({ value }) => value ? 'Oui' : 'Non'
    },
    {
        field: 'balance',
        headerName: 'Montant',
        flex: 1,
        valueFormatter: ({ value }) => `${value} DA`
    },
    {
      field: 'consumedAmount',
      headerName: 'Montant consommé',
      flex: 1,
      valueFormatter: ({ value }) => `${value} DA`
    },
    {
        field: 'expiryDate',
        headerName: 'Date d\'expiration',
        flex: 1,
        valueFormatter: ({ value }) => new Date(value).toLocaleDateString('fr-FR')
    },

    {
        field: 'actions',
        headerName: 'Actions',
        width: 135,
        renderCell: ({ row }) => (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    gap: 10
                }}
            >
                <IconButton onClick={() => onEdit(row)}>
                    <Edit color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(row)}>
                    <DeleteOutline color="error" />
                </IconButton>
            </div>
        )
    }
];

  return (
    <Card {...rest} style={{ width: '100%', height: '60vh' }}>
     {
      fetching ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
        :
        <DataGrid
        rows={list}
        columns={columns}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        loading={fetching}
        paginationMode="server"
        isRowSelectable={false}
        rowCount={totalCount}
        disableSelectionOnClick
        getRowId={(row) => row._id || row.id}
       />
     }
    </Card>
  );
};

export default GiftCardResults;
