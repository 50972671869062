import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import WishListPartnerResults from 'src/components/wishlist-partner/WishlistPartnerResults';
import { WishlistPartnerProvider } from 'src/contexts/WishlistPartnerContext';

const WishlistPartner = () => {
  const history = useHistory();
  const match = useRouteMatch();
  
  return (
    <>
      <Helmet>
        <title>Izya | Join List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <WishListPartnerResults />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default () => (
  <WishlistPartnerProvider>
    <WishlistPartner />
  </WishlistPartnerProvider>
);
