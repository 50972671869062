import React, { useEffect } from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const GiftCardContext = React.createContext({});

export const GiftCardProvider = ({ children }) => {
  const [giftCards, setGiftCards] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [giftCard, setGiftCard] = React.useState(null);
  const [toDelete, setToDelete] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const fetchGiftCards = async () => {
    try {
      setFetching(true);
      const { data } = await axios.get(`/v1/admin/gift-card/query?page=${page + 1}`);      
      setGiftCards(data.results);
      setTotalCount(data.totalResults);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(()=>{
    fetchGiftCards()
  }, [page])

  const addGiftCard = async (data) => {
    try {
      const { data: created } = await axios.post('/v1/admin/gift-card', data);
      setGiftCards([...giftCards, created]);
      return Promise.resolve(created);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateGiftCard = async (data) => {
    try {
      const { data: updated } = await axios.patch(`/v1/admin/gift-card/${giftCard._id}`, {
        ...data,
      });
      setGiftCards(giftCards.map((g) => g._id === giftCard._id ? updated : g));
      setGiftCard(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteGiftCard = async () => {
    try {
      await axios.delete(`/v1/admin/gift-card/${toDelete._id}`, {});
      setGiftCards(giftCards.filter((g) => g._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const printGiftCards = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/v1/admin/gift-card/print', { ids: selectedItems }, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'giftcards.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  }


  React.useEffect(() => {
    fetchGiftCards(search);
  }, [search]);

  return (
    <GiftCardContext.Provider
      value={{
        giftCards,
        fetching,
        fetchGiftCards,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        giftCard,
        setGiftCard,
        addGiftCard,
        updateGiftCard,
        deleteGiftCard,
        printGiftCards,
        toDelete,
        setToDelete, 
        selectedItems,
        setSelectedItems,
        loading,
        setLoading
      }}
    >
      {children}
    </GiftCardContext.Provider>
  );
};

export const useGiftCard = () => React.useContext(GiftCardContext);
