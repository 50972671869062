import React from 'react';
import { Card, Chip, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useAppointement } from 'src/contexts/AppointementContext';
import Empty from 'src/assets/Empty';
import { useWishlistPartner } from 'src/contexts/WishlistPartnerContext';
import { set } from 'lodash';

const WishListPartnerResults = () => {
  const { fetchWishlistPartner, page, setPage, fetching, pageSize, totalCount, wishlistPartner } =
    useWishlistPartner();

    
  const { id } = useParams();
  const [list, setList] = React.useState([]);

  const getName = (client) => client.name

  React.useEffect(() => {
    setList(wishlistPartner);
  }, [fetchWishlistPartner]);

  const columns = [
    {
      field: 'name',
      headerName: 'Nom',
      valueFormatter: ({ value }) => value.name,
      flex: 1
    },
    {
        field: 'surname',
        headerName: 'Prénom',
        valueFormatter: ({ value }) => value.surname,
        flex: 1
    },
    {
        field: 'email',
        headerName: 'Email',
        valueFormatter: ({ value }) => value.name,
        flex: 1
      },
    {
        field: 'phone',
        headerName: 'Téléphone',
        valueFormatter: ({ value }) => value.name,
        flex: 1
      },
      {
        field: 'type',
        headerName: 'Type',
        valueFormatter: ({ value }) => value[0].toUpperCase() + value.slice(1),
        flex: 1
      },
      {
        field: 'company_name',
        headerName: 'Nom de l\'entreprise',
        valueFormatter: ({ value }) => value,
        flex: 1
      },
      {
        field: 'wilaya',
        headerName: 'Wilaya',
        valueFormatter: ({ value }) => value,
        flex: 1
      },
      {
        field: 'rc_number',
        headerName: 'Registre de commerce',
        valueFormatter: ({ value }) => value,
        flex: 1
      },
      {
        field: 'artisan_card_number',
        headerName: 'Carte d\'artisan',
        valueFormatter: ({ value }) => value,
        flex: 1
      },
    {
      field: 'createdAt',
      headerName: 'Date de création',
      valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
      flex: 1
    }
  ];
  return (
    <Card style={{ width: '100%', height: '60vh' }}>
      {list.length === 0 ? (
        <Empty label="Aucune demande !" />
      ) : (
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          paginationMode="server"
          rowCount={totalCount}
          loading={fetching}
          getRowId={(row) => row._id || row.id}
        />
      )}
    </Card>
  );
};

export default WishListPartnerResults;
