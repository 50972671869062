import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import DeleteModal from 'src/components/DeleteModal';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import { GiftCardProvider, useGiftCard } from 'src/contexts/GiftCardContext';
import GiftCardToolbar from 'src/components/gift-card/GiftCardToolbar';
import GiftCardResults from 'src/components/gift-card/GiftCardResults';
import GiftCardModal from 'src/components/gift-card/GiftCardModal';


const GiftCard = () => {
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const {
    setGiftCard,
    addGiftCard,
    updateGiftCard,
    deleteGiftCard,
    setToDelete,
    toDelete
  } = useGiftCard();

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const onEdit = (row) => {
    setGiftCard(row);
    setEditModal(true);
  };

  const onDelete = (row) => {
    setToDelete(row);
    setDeleteModal(true);
  };

  const submitAdd = async (data, { resetForm }) => {
    try {
      const validData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => !!value)
      );
      await addGiftCard(validData);
      setAddModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  const submitUpdate = async (data, { resetForm }) => {
    try {
      await updateGiftCard(data);
      setEditModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const submitDelete = async () => {
    try {
      await deleteGiftCard();
      setDeleteModal(false);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Carte cadeaux | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <GiftCardToolbar openModal={() => setAddModal(true)} />
          <Box sx={{ pt: 3 }}>
            <GiftCardResults onEdit={onEdit} onDelete={onDelete} />
          </Box>
        </Container>
      </Box>
      <GiftCardModal
        open={addModal}
        handleClose={() => setAddModal(false)}
        handleSubmit={submitAdd}
        title="Ajouter une spécialité"
        buttonTitle="Ajouter"
      />
      <GiftCardModal
        open={editModal}
        handleClose={() => {
          setGiftCard(null);
          setEditModal(false);
        }}
        handleSubmit={submitUpdate}
        title="Modifier"
        buttonTitle="Sauvegarder"
      />
      <DeleteModal
        open={deleteModal}
        handleClose={closeDeleteModal}
        handleDelete={submitDelete}
        message={`Êtes vous sur de vouloir supprimer la spécialité "${toDelete?.code}"`}
      />
    </>
  );
};

export default () => (
  <GiftCardProvider>
    <GiftCard />
  </GiftCardProvider>
);
