import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    DialogTitle,
    FormHelperText,
    FormControl
} from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useGiftCard } from 'src/contexts/GiftCardContext';

const GiftCardModal = ({
    open,
    handleClose,
    handleSubmit,
    title,
    buttonTitle
}) => {
    const { giftCard } = useGiftCard();


    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <Formik
                initialValues={{
                    code: giftCard?.code || null,
                    balance: giftCard?.balance || 0,
                    expiryDate: giftCard?.expiryDate || new Date().toISOString().split('T')[0]
                }}
                validationSchema={yup.object().shape({
                    code: yup.string().matches(/^izya-[a-zA-Z0-9]{10}-\d{4}$/i, 'Code is not valid').optional().nullable(),
                    balance: yup.number().min(0, 'Montant ne peut pas être inférieur à 0').required('Champ obligatoire.'),
                    expiryDate: yup.date().optional().default(new Date())
                })}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit: onSubmit, handleChange, values, errors }) => (
                    <form onSubmit={onSubmit}>
                        <DialogContent>
                            <FormControl fullWidth error={!!errors.code}>
                                <TextField
                                    error={!!errors.code}
                                    autoFocus
                                    margin="dense"
                                    id="code"
                                    label="Code de la carte"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={values.code}
                                    onChange={handleChange('code')}
                                />
                                {!!errors && !!errors.code && (
                                    <FormHelperText
                                    style={{
                                        color: 'red'
                                    }}
                                    sx={{ marginLeft: 0.5 }}>
                                        {errors.code}
                                    </FormHelperText>
                                )}
                                <FormHelperText>
                                    Exemple: izya-abcdefghij-1234
                                </FormHelperText>

                                <TextField
                                    error={!!errors.balance}
                                    autoFocus
                                    margin="dense"
                                    id="balance"
                                    label="Montant"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={values.balance}
                                    onChange={handleChange('balance')}
                                />
                                {!!errors && !!errors.balance && (
                                    <FormHelperText
                                        style={{
                                            color: 'red'
                                        }}
                                        sx={{ marginLeft: 0.5 }}>
                                        {errors.balance}
                                    </FormHelperText>
                                )}

                                <TextField
                                    error={!!errors.expiryDate}
                                    autoFocus
                                    margin="dense"
                                    id="expiryDate"
                                    type="date"
                                    label="Date d'expiration"
                                    fullWidth
                                    variant="outlined"
                                    value={values.expiryDate}
                                    onChange={handleChange('expiryDate')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        sx: {
                                            "& input": {
                                                padding: "12px",
                                            },
                                        },
                                    }}
                                />
                                {!!errors && !!errors.expiryDate && (
                                    <FormHelperText style={{
                                        color: 'red'
                                    }} sx={{ marginLeft: 0.5 }}>
                                        {errors.expiryDate}
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Annuler
                            </Button>
                            <Button onClick={onSubmit}>{buttonTitle}</Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default GiftCardModal;
