import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  FormHelperText,
  FormControl,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useSpecialty } from 'src/contexts/SpecialtyContext';
import { Formik } from 'formik';
import * as yup from 'yup';

const SpecialtiesModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  buttonTitle
}) => {
  const { specialty } = useSpecialty();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          name: specialty?.name || '',
          atHome: specialty?.atHome || false
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Champ obligatoire.')
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit: onSubmit, handleChange, values, errors }) => (
          <form onSubmit={onSubmit}>
            <DialogContent>
              <FormControl fullWidth error={!!errors.name}>
                <TextField
                  error={!!errors.name}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nom de la spécialité"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange('name')}
                />
                {!!errors && !!errors.name && (
                  <FormHelperText sx={{ marginLeft: 0.5 }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.atHome}
                      onChange={handleChange('atHome')}
                      name="atHome"
                      color="primary"
                    />
                  }
                  label="À domicile"
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
              <Button onClick={onSubmit}>{buttonTitle}</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SpecialtiesModal;
