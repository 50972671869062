import React from 'react';
import { Card, CircularProgress, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { useSpecialty } from 'src/contexts/SpecialtyContext';

const SpecialtiesResults = ({ onEdit, onDelete, ...rest }) => {
  const { specialties, page, setPage, pageSize, totalCount, fetching, search, addSpecialty } =
    useSpecialty();
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (search.trim()) {
      setList(
        specialties.filter((s) =>
          s.name.toLowerCase().startsWith(search.toLowerCase())
        )
      );
      return;
    }
    setList(specialties);
  }, [specialties, search]);

  const columns = [
    {
      field: 'name',
      headerName: 'Spécialité',
      flex: 1
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 135,
      renderCell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 10
          }}
        >
          <IconButton onClick={() => onEdit(row)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => onDelete(row)}>
            <DeleteOutline color="error" />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <Card {...rest} style={{ width: '100%', height: '60vh' }}>
     {
      fetching ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
        :
        <DataGrid
        rows={list}
        columns={columns}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        loading={fetching}
        paginationMode="server"
        isRowSelectable={false}
        rowCount={totalCount}
        disableSelectionOnClick
        getRowId={(row) => row._id || row.id}
       />
     }
    </Card>
  );
};

export default SpecialtiesResults;
