import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const WishlistPartnerContext = React.createContext({});

export const WishlistPartnerProvider = ({ children }) => {
  const [wishlistPartner, setWishlistPartner] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);

  const fetchWishlistPartner = async (currentPage = 0) => {
    try {
      setFetching(true);
      const { data } = await axios.get(
        `v1/wishlist-partner?page=${currentPage}&limit=${pageSize}`
      );
      setWishlistPartner(data.results);
      
      
      setTotalCount(data.totalResults);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    fetchWishlistPartner();
  }, []);

  React.useEffect(() => {
    if (page !== 0) {
      fetchWishlistPartner(page + 1);
    }
  }, [page]);

  return (
    <WishlistPartnerContext.Provider
      value={{
        wishlistPartner,
        fetching,
        fetchWishlistPartner,
        page,
        setPage,
        pageSize,
        totalCount
      }}
    >
      {children}
    </WishlistPartnerContext.Provider>
  );
};

export const useWishlistPartner = () => React.useContext(WishlistPartnerContext);
